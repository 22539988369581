import React from 'react';
import { Layout } from '../components/Layout';

const Policies = () => (
  <Layout
    title="Policies, Terms & Conditions | Axiom Geomatics | Calgary, Red Deer"
    description="Policies, terms and conditions"
    keywords=""
  >
    <main>
      <h1>Policies, Terms & Conditions</h1>

      <p>
        <strong>Feb 14, 2024</strong>
      </p>

      <p>
        The cost of credit card processing has risen in recent years, and now impacts the economics
        of land surveying in Alberta. Like many providers within Canada, there is a need to pass
        costs for this on to the customers. To treat all of our clients fairly, Axiom Geomatics may
        charge up to 2.4% credit card surcharge for clients choosing to pay with a credit card.
        Please inquire if you would like to use other payment methods. This may start as early as
        March 15, 2024.
      </p>

      <p>
        As a mitigating measure, against outstanding invoices, Axiom Geomatics now has charges for
        overdue payments.
      </p>

      <p>
        Overdue invoices will be charged 2% per month, 24% per annum. This will not affect current
        outstanding invoices.
      </p>
    </main>
  </Layout>
);

export default Policies;
